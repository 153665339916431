import { Card, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useRef, useState } from 'react';
import UseRadioGroup from './RadioButtons';
import NewTooltip from './Tooltip';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Loader from '../Loader';
import { getFormattedNumber } from '../../utils/currency.utils';
import HorizontalCandleStick from '../charts/HorizontalCandleStick';
import CircularLoader from './CircularLoader';
import InformationTooltip from './InformationTooltip';
import { CurveDashedLine } from '../../icons/icons';
import { getDateOrRange } from '../../utils/date.utils';
import { useRouter } from 'next/router';
import { candleChartColoring } from '../../utils/overview.utils';

const ranges = [
  { value: 'Daily', label: 'Yesterday' },
  { value: 'Weekly', label: 'This Week' },
  { value: 'Monthly', label: 'This Month' },
  { value: 'Yearly', label: 'This Year' }
];

const SummaryCard = props => {
  const data = props.data;
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState(data?.range);

  const getRangeLabel = range => {
    return ranges.find(item => item.value === range)?.label;
  };

  const selectvalue = range => {
    setSelectedRange(range);
    handleTooltipClose();
    props.updateRange(range);
    // data.fetchData({variables: {frequency: range}, requestPolicy: 'network-only'})
  };

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (data?.range) {
      setSelectedRange(data.range);
    }
  }, [props]);

  const widthRef = useRef(null);

  const router = useRouter();
  const isOverPage = router.asPath.includes('/overview');
  const colors =
    isOverPage &&
    candleChartColoring(
      data?.value?.rangeLower,
      data?.value?.rangeUpper,
      data?.value?.metricValue
    );
  return (
    <Card className={classes.card} data-cy={data?.title}>
      {props?.fetching && <Loader />}
      {data && (
        <div>
          <div className={classes.header}>
            <div className={classes.headerleft}>
              <div className={classes.title}>{data?.title}</div>
              <span style={{ fontSize: '12px', color: '#848EA0' }}>
                {data.range && getDateOrRange(selectedRange)}
              </span>
            </div>
            {data.range ? (
              <NewTooltip
                placement="bottom-end"
                handleTooltipClose={handleTooltipClose}
                handleTooltipOpen={handleTooltipOpen}
                open={open}
                title={
                  <UseRadioGroup
                    data={ranges}
                    setValue={selectvalue}
                    value={selectedRange}
                  />
                }
              >
                <div className={classes.selector} data-cy="range">
                  {getRangeLabel(selectedRange)}
                  <ArrowDropDown />
                </div>
              </NewTooltip>
            ) : (
              <InformationTooltip info={props?.info} />
            )}
          </div>
          <div className={classes.main}>
            {props?.fetching ? (
              <div className={classes.loader}>
                <CircularLoader />
              </div>
            ) : (
              <div
                className={
                  props.subTitle ? classes.contentRow : classes.content
                }
              >
                <div
                  className={`${classes.value} ${
                    props?.wavyLine || colors?.waveLine ? classes?.wavyLine : ''
                  }`}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '38px'
                  }}
                >
                  <span
                    data-cy="value"
                    ref={widthRef}
                    style={{
                      lineHeight: '30px',
                      // color: colors?.titleColor ? colors?.titleColor : ''
                    }}
                  >
                    {props?.dollarSign && '$'}
                    {getFormattedNumber(data.value?.metricValue)}
                  </span>
                  <span style={{ width: '100%' }}>
                    {(props?.wavyLine &&
                      data?.value?.metricValue &&
                      data?.value?.metricValue > data?.value?.rangeUpper &&
                      String(getFormattedNumber(data.value?.metricValue))
                        ?.length < 5) ||
                    colors?.waveLine ? (
                      <span>
                        <CurveDashedLine width={100} />
                      </span>
                    ) : (props?.wavyLine &&
                        data?.value?.metricValue &&
                        data?.value?.metricValue > data?.value?.rangeUpper &&
                        String(getFormattedNumber(data.value?.metricValue))
                          ?.length > 5) ||
                      colors?.waveLine ? (
                      <span>
                        <CurveDashedLine width={120} />
                      </span>
                    ) : (
                      ''
                    )}
                  </span>
                </div>
                {props.range && (
                  <div className={classes.candleStick}>
                    <HorizontalCandleStick
                      highest={
                        data?.value?.rangeLower + data?.value?.rangeUpper
                      }
                      lowest={0}
                      opening={data?.value?.rangeLower}
                      closing={data?.value?.rangeUpper}
                      current={data?.value?.metricValue}
                      unit={props?.rangeParams === false ? '' : '$'}
                    />
                  </div>
                )}

                {props.subTitle && (
                  <div className={classes.description}>{data.description}</div>
                )}
              </div>
            )}
          </div>
          {data.footer && (
            <div className={classes.footer} data-cy="footer">
              {data.footer.map((item, index) => (
                <div
                  key={index}
                  className={`${classes.footerItem} ${
                    data.footer.length > 1 && index === 0
                      ? classes.borderRight
                      : ''
                  }`}
                >
                  <Typography
                    data-cy="description"
                    className={classes.description}
                  >
                    {item.description}
                  </Typography>
                  <Typography
                    className={classes.footerValue}
                    color={item.color}
                    data-cy="value"
                  >
                    {item.prefix || ''}
                    {getFormattedNumber(item.value)}
                  </Typography>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

// style constant
const useStyles = makeStyles()(theme => {
  return {
    wavyLine: {},
    borderRight: {
      borderRight: '1px solid #EEEEEE'
    },
    card: {
      height: '100%',
      borderRadius: 8
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      padding: 10,
      minHeight: 57
    },
    main: {
      minHeight: 100,
      borderTop: '1px solid #f1f3f6',
      display: 'flex'
    },
    description: {
      color: 'gray',
      fontSize: 12
    },
    selector: {
      backgroundColor: '#eff5ff',
      color: theme.palette.primary.main,
      padding: '2px 12px',
      borderRadius: 8,
      display: 'flex',
      alignItems: 'center'
    },
    loader: {
      display: 'flex',
      justifyContent: 'center',
      flex: 1
    },
    content: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderTop: '1px solid #f1f3f6',
      padding: 25,
      minHeight: 120,
      width: '100%'
    },
    contentRow: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 35,
      minHeight: 120,
      width: '100%'
    },
    footer: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #f1f3f6',
      height: 70
    },
    title: {
      fontSize: 16,
      fontWeight: '500',
      color: '#465671'
    },
    icon: {
      backgroundColor: '#eff5ff',
      borderRadius: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 35,
      height: 35
    },
    value: {
      fontSize: 35,
      fontWeight: '500',
      textAlign: 'center',
      flex: 1,
      color: '#387ED0'
    },
    footerItem: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1,
      padding: '0px 0'
    },
    footerValue: {
      fontSize: 24,
      fontWeight: '500',
      paddingTop: 0
    },
    candleStick: {
      width: '60%'
    }
  };
});

export default SummaryCard;
