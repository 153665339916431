import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

const StyledFormControlLabel = styled(props => <FormControlLabel {...props} />)(
  ({ theme, checked }) => ({
    '.MuiFormControlLabel-label': checked && {
      color: theme.palette.primary.main
    }
  })
);

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

MyFormControlLabel.propTypes = {
  value: PropTypes.any
};

export default function UseRadioGroup({
  data,
  value,
  setValue,
  id,
  renderExtraComponent
}) {
  return (
    <>
      <RadioGroup
        id={id || ''}
        data-cy={`range-select`}
        name="use-radio-group"
        value={value}
        onChange={e => setValue(e.target.value)}
        style={{
          padding: '5px 15px',
          boxShadow: '0px 0px 24px 0px rgba(13,110,253,0.25)'
        }}
      >
        {data?.map(item => (
          <MyFormControlLabel
            key={item?.value}
            value={item?.value}
            label={item?.label}
            control={
              <Radio
                data-cy={item.value}
                size="small"
                sx={{ padding: '5px' }}
                checked={item.value === value}
              />
            }
            style={{ marginBottom: 0 }}
          />
        ))}
      </RadioGroup>

      {renderExtraComponent || ''}
    </>
  );
}
