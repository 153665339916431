import { Card, Grid } from '@mui/material';
import React from 'react';

import AutoComplete from '../forms/inputs/AutoComplete';
import { makeStyles } from 'tss-react/mui';

import Action from './Action';
import { checkRequiredResponsiveSizeUsingCssMediaQuery } from '../../utils/resposive.utils';
import { SearchIcon } from '../../icons/icons';

const FilterComponent = ({ handleSearch, hospital, selectFilters, disabled, healthSystem }) => {
  const { classes } = useStyles();

  const matches =
    checkRequiredResponsiveSizeUsingCssMediaQuery('(max-width:550px)');
  return (
    <Card className={classes.card} style={{ opacity: disabled ? 0.4 : 1, pointerEvents: disabled ? 'none' : 'all' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} md={4} lg={3}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              fontSize: '20px',
              fontWeight: 700,
              color: ' #387ED0',
              borderRight: matches ? '' : '1px solid #E0E0E0'
            }}
          >
            {hospital?.label || healthSystem?.label || 'Health System'}
          </div>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={6}>
          <Grid
            container
            style={{
              border: '1px solid #387ED0',
              borderRadius: '10px',
              height: '45px',
              padding: '0px'
            }}
          >
            <>
              <div style={{ flex: 1 }}>
                <AutoComplete handleSearch={handleSearch} />
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '15px'
                }}
              >
                <SearchIcon />
              </div>
            </>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={3}>
          <div
            style={{
              display: 'flex',
              justifyContent: matches ? 'flex-start' : 'end',
              borderLeft: matches ? '' : '1px solid #E0E0E0'
            }}
          >
            <Action selectFilters={selectFilters} />
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

const useStyles = makeStyles()(theme => {
  return {
    card: {
      display: 'flex',
      alignItems: 'center',
      height: 'auto',
      boShadow: '0px 0px 24px rgba(182, 211, 255, 0.3)',
      borderRadius: '8px',
      marginBottom: '15px',
      padding: '10px'
    },
    'css-vjlexj-MuiInputBase-input': {
      '&::placeholder': {
        color: 'blue'
      }
    }
  };
});
export default FilterComponent;
