import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { homePageSearch } from '../../../queries/overview';
import { useDebounce } from 'use-debounce';
import { useQuery } from 'react-query';
import { List, ListItem, Popper } from '@mui/material';
import { capitalizeFirstLetter } from '../../../utils/forms.utils';
import { makeStyles } from 'tss-react/mui';
import { useRecoilState } from 'recoil';
import { currentHealthSystemIdState } from '../../../recoils/analysis.recoil.state';

const medicationVisible = process.env.NEXT_PUBLIC_SHOW_MEDICATION !== 'false';
const physicianVisible = process.env.NEXT_PUBLIC_SHOW_PHYSICIAN !== 'false';
const therapeuticVisible = process.env.NEXT_PUBLIC_SHOW_UTILIZATION !== 'false';

const CustomListbox = React.forwardRef(({ children, ...rest }, ref) => {

  return (
    <List
      style={{ display: 'flex' }}
      ref={ref}
      dense
      disablePadding
      className="custom-listbox"
      {...rest}
    >
      {children?.map(child => child)}
    </List>
  );
});
export default function Grouped({ handleSearch }) {
  const [query, setQuery] = React.useState('');
  const [value] = useDebounce(query, 1000);
  const [options, setOptions] = React.useState([]);
  const [d, setD] = React.useState('');

  const [hospitalCount, setHospitalCount] = React.useState(4);
  const [medicationCount, setMedicationCount] = React.useState(4);
  const [physicianCount, setPhysicianCount] = React.useState(4);
  const [therapeuticCount, setTherapeuticCount] = React.useState(4);

  const [hospitalResults, setHospitalResults] = React.useState([]);
  const [medicationResults, setMedicationResults] = React.useState([]);
  const [physicianResults, setPhysicianResults] = React.useState([]);
  const [therapeuticResults, setTherapeuticResults] = React.useState([]);
  const [healthSystem] = useRecoilState(currentHealthSystemIdState);
  const healthSystemId = healthSystem?.value;
  const getQuery = () => {
    let allQuery = {};

    if (value) {
      allQuery.queryString = value;
    }
    allQuery.healthSystemId = healthSystemId;

    return allQuery;
  };

  const { isLoading } = useQuery(
    ['search', value],
    () => homePageSearch(getQuery()),
    {
      onSuccess: data => {
        setHospitalCount(4);
        setPhysicianCount(4);
        setTherapeuticCount(4);
        setMedicationCount(4);
        setOptions(getFinalOptions(data));
      },
      enabled: !!value && !!healthSystemId
    }
  );

  React.useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        setQuery(d?.genericName || d?.name || d?.description || d);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [d]);

  const getFinalOptions = results => {
    let options = [];

    if (
      results?.homepageSearch?.drugSearchResults?.length &&
      medicationVisible
    ) {
      setMedicationResults(results?.homepageSearch?.drugSearchResults);
      options = [
        ...options,
        ...results?.homepageSearch?.drugSearchResults
          ?.map(item => ({
            ...item,
            genericNameCapitalize: capitalizeFirstLetter(item?.genericName),
            type: 'Medication'
          }))
          .slice(0, medicationCount)
      ];
    }

    if (
      results?.homepageSearch?.theraputicSearchResults?.length &&
      therapeuticVisible
    ) {
      setTherapeuticResults(results?.homepageSearch?.theraputicSearchResults);
      options = [
        ...options,
        ...results?.homepageSearch?.theraputicSearchResults
          ?.map(item => ({
            ...item,
            type: 'Theraputic'
          }))
          .slice(0, therapeuticCount)
      ];
    }



    if (
      results?.homepageSearch?.physicianSearchResults?.length &&
      physicianVisible
    ) {
      setPhysicianResults(results?.homepageSearch?.physicianSearchResults);
      options = [
        ...options,
        ...results?.homepageSearch?.physicianSearchResults
          ?.map(item => ({
            ...item,
            type: 'Physician'
          }))
          .slice(0, physicianCount)
      ];
    }
    if (results?.homepageSearch?.hospitalSearchResults?.length) {
      setHospitalResults(results?.homepageSearch?.hospitalSearchResults);
      options = [
        ...options,
        ...results?.homepageSearch?.hospitalSearchResults
          ?.map(item => ({
            ...item,
            type: 'Hospital'
          }))
          .slice(0, hospitalCount)
      ];
    }

    return options;
  };

  const showMoreList = type => {
    if (type === 'Hospital') {
      setOptions([
        ...options.filter(item => item.type === 'Medication'),
        ...options.filter(item => item.type === 'Theraputic'),
        ...hospitalResults.map(item => ({
          ...item,
          type: 'Hospital'
        })),
        ...options.filter(item => item.type === 'Physician')
      ]);
      setHospitalCount(hospitalResults.length);
    } else if (type === 'Medication') {
      setOptions([
        ...medicationResults.map(item => ({
          ...item,
          type: 'Medication'
        })),
        ...options.filter(item => item.type === 'Theraputic'),
        ...options.filter(item => item.type === 'Hospital'),
        ...options.filter(item => item.type === 'Physician')
      ]);
      setMedicationCount(medicationResults.length);
    } else if (type === 'Physician') {
      setOptions([
        ...options.filter(item => item.type === 'Medication'),
        ...options.filter(item => item.type === 'Theraputic'),
        ...options.filter(item => item.type === 'Hospital'),
        ...physicianResults.map(item => ({
          ...item,
          type: 'Physician'
        }))
      ]);
      setPhysicianCount(physicianResults.length);
    } else if (type === 'Theraputic') {
      setOptions([
        ...options.filter(item => item.type === 'Medication'),
        ...therapeuticResults.map(item => ({
          ...item,
          type: 'Theraputic'
        })),
        ...options.filter(item => item.type === 'Hospital'),
        ...options.filter(item => item.type === 'Physician')
      ]);
      setTherapeuticCount(therapeuticResults.length);
    }
  };

  const hasMore = type => {
    if (
      (type === 'Hospital' && hospitalResults.length > hospitalCount) ||
      (type === 'Medication' && medicationResults.length > medicationCount) ||
      (type === 'Physician' && physicianResults.length > physicianCount) ||
      (type === 'Theraputic' && therapeuticResults.length > therapeuticCount)
    ) {
      return true;
    }
  };

  const CustomPaper = function (props) {
    const { classes } = useStyles();
    return <Popper {...props} className={classes.root} />;
  };

  return (
    <>
      <Autocomplete
        freeSolo
        id="grouped-demo"
        PopperComponent={CustomPaper}
        options={query ? options : []}
        groupBy={option => option.type}
        getOptionLabel={option =>
          option?.name ||
          option?.genericNameCapitalize ||
          option?.genericName ||
          option?.brandName ||
          option?.description ||
          option?.id
        }
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #387ED0',
            padding: '3px',
            borderRadius: 2
          },
          '& .MuiOutlinedInput-root': {
            padding: '3px 5px',
            '& input::placeholder': {
              fontStyle: 'italic',
              fontSize: 15,
              color: '#080808',
              fontWeight: '400'
            }
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            sx={{
              '& .MuiInputBase-root': {
                borderRadius: 10,
                height: '40px',
                overflow: 'hidden',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none'

                  // borderColor: '#397ed0'
                }
              }
            }}
            placeholder="Search hospital, medication, or therapeutics"
            InputLabelProps={{ style: { color: '#387ED0' } }}
            data-cy="search"
          />
        )}
        renderGroup={params => {
          return (
            <li data-cy="search-results">
              <div
                style={{
                  fontWeight: '600',
                  paddingLeft: 10,
                  paddingTop: 5,
                  paddingBottom: 5
                }}
              >
                {params.group}
              </div>
              <div
                style={{
                  borderBottom: '1px solid #f1f3f6',
                  paddingBottom: 5,
                  marginBottom: 10
                }}
              >
                {params.children}
                {hasMore(params.group) && (
                  <li
                    onClick={() => showMoreList(params.group)}
                    style={{
                      paddingLeft: 15,
                      paddingTop: 3,
                      cursor: 'pointer',
                      marginBottom: 5,
                      color: '#387ED0',
                      textDecoration: 'underline'
                    }}
                  >
                    Show More
                  </li>
                )}
              </div>
            </li>
          );
        }}
        ListboxComponent={props => <CustomListbox {...props} role="listbox" />}
        onInputChange={(e, value) => {
          setQuery(value);
        }}
        noOptionsText="Type to search"
        onChange={(e, value) => {
          handleSearch(value);
          setD(value);
        }}
        inputValue={query ? query : ''}
        loading={!query ? false : isLoading}
        filterOptions={options => {
          return options;
        }}
        getOptionSelected={(option, value) => {
          return option.id === value.id;
        }}
      />
    </>
  );
}

const useStyles = makeStyles()(theme => {
  return {
    root: {
      borderRadius: 5,
      boxShadow: '0px 0px 5px 5px #387ED020;'
    }
  };
});
