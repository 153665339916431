import { Box, Typography } from "@mui/material"
import Link from "next/link";
import { useRouter } from "next/router";
import { useQuery } from "urql";
import { ActivityDetails, ListActivity } from "../../queries/activity";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../Loader";

const FromMyActivity = () => {
    const router = useRouter();
    const { activityId } = router.query;

    const [res] = useQuery({ query: ActivityDetails, variables: { id: parseInt(activityId) } })
    const summary = res.data?.getActivityDetails?.summary;

    useEffect(() => {
        if (res.error) {
            toast.error(res.error.message)
        }
    }, [res])

    return <Box sx={{ padding: 1, paddingTop: 2, paddingBottom: 2, mb: 2, background: '#fff' }} borderRadius={2} display={"flex"} justifyContent={"space-between"}>
        {res.fetching && <Loader />}
        <div className="summary" dangerouslySetInnerHTML={{ __html: summary }} />
        <Link href={`/my-activities`}>Go to My Activity</Link>
    </Box>
}

export default FromMyActivity;