import { removeNullfromArray } from "./physician.utils";

export const getMedicationInfoFromSlug = medicationSlug => {
  if (!medicationSlug) {
    return [null, null, null];
  }
  try {
    const decodedMedicationSlug = decodeURI(medicationSlug);
    return decodedMedicationSlug.split('--');
  } catch (err) {
    return [null, null, null];
  }
};

export const removeInsights = router => {
  if (router.pathname === '/medication') {
    delete router?.query?.showInsights;
    router.push(
      {
        pathname: router.route,
        query: {
          ...router?.query
        }
      },
      undefined,
      { shallow: true }
    );
  }
};

export const isInRange = ({ currentt, opening, closing }) => {
  return (
    parseFloat(currentt) >= parseFloat(opening) &&
    parseFloat(currentt) <= parseFloat(closing)
  );
};

export const getCommonFiltersParams = ({
  hospitalFilter,
  therapeuticsFilter,
  secondaryTherapeuticsFilter,
  patientFilter
}) => {
  const params = {};
  if (hospitalFilter?.length) {
    params.hospitals = hospitalFilter?.map(item => item?.value);
  }

  if (therapeuticsFilter?.length) {
    params.therapeutics = removeNullfromArray(therapeuticsFilter)?.map(item => item?.value);
  }

  if (secondaryTherapeuticsFilter?.length) {
    params.secondaryTherapeutics =removeNullfromArray(secondaryTherapeuticsFilter)?.map(item => item?.value);
  }

  if (patientFilter?.length) {
    params.patient = patientFilter?.map(item => item?.value);
  }

  return params;
};


