import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getUtilizationTrend } from '../../../queries/overview';
import SummaryCard from '../../shared/SummaryCard';

export default function UtilizationTrend({ healthSystemId, hospitalId, type }) {
  const frequency = 'Daily';
  let utilizationTrendData,
    utilizationTrendDescription = null;

  const { data, isFetching, isLoading, error } = useQuery(
    ['getUtilizationTrend', healthSystemId, hospitalId, frequency, type],
    () =>
      getUtilizationTrend({
        healthSystemId,
        hospitalId,
        frequency
      }),
    {
      enabled: type
        ? !!healthSystemId && !!hospitalId && !!frequency
        : (!!healthSystemId || !!hospitalId) && !!frequency
    }
  );
  if (data) {
    utilizationTrendData = data.utilizationTrendDashTabMetric;
    utilizationTrendDescription = `Over last 6 months`;
  }

  const utilizationTrend = {
    title: 'Utilization Trend',
    info: 'Information about Medication spend',
    range: null,
    value: utilizationTrendData,
    description: utilizationTrendDescription,
    icon: faDollarSign,
    iconColor: 'red',
    healthSystemId,
    footer: [
      {
        description: 'Budget Impact Potential',
        value: `${utilizationTrendData?.savingPotential}`,
        prefix: '$',
        color: 'rgb(255, 87, 51)'
      }
    ]
  };

  return (
    <SummaryCard
      data={utilizationTrend}
      subTitle={true}
      fetching={isLoading||isFetching}
      info="Summary of new utilization trends & dynamics"
    />
  );
}
