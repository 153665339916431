import React, { useRef } from 'react';
import { getFormattedNumber } from '../../utils/currency.utils';
import { ParentSize } from '@visx/responsive';
import { isInRange } from '../../utils/medication.utils';

const CandleStick = ({
  highest,
  lowest,
  opening,
  closing,
  current,
  unit = '',
  barWidth = 130,
  showHeader,
  candleColor = '#387ED0',
  shadowHeight = 3,
  height = 8
}) => {
  const containerRef = useRef(null);

  const isCurrentInRange = isInRange({ current, opening, closing });

  const calculateCloseness = (open, close) => {
    const high = highest ? barWidth : open + close;
    const leftPos = (open * 100) / high;
    const rightPos = (close * 100) / high;
    return rightPos - leftPos;
  };

  const renderBarWidth=0.95*barWidth;

  const getScale = () => {
    let open,
      close,
      currentValue = 0;

    const high = Math.max(highest || 0, Math.max(closing, current) + opening);
    open = (((opening * 100) / high) * renderBarWidth) / 100;
    close = (((closing * 100) / high) * renderBarWidth) / 100;
    currentValue = (((current * 100) / high) * renderBarWidth) / 100;

    return { open: open, close: close, currentValue: currentValue };
  };

  const { open, close, currentValue } = getScale();

  const calculatePosition = val => {
    const high = highest ? renderBarWidth : open + close;
    const percent = (val * 100) / high;
    return percent;
  };

  const calculateClosingPosition = () => {
    return calculatePosition(close) < 96
      ? close - 5
      : calculatePosition(close) < 98
      ? close - getFormattedNumber(closing).length * 9
      : close - getFormattedNumber(closing).length * 10;
  };

  const showCloseValue = calculateCloseness(open, close) > 10;
  const showOpenValue = calculateCloseness(open, close) > 10 || !showCloseValue;


  const padding = 0;

  return (
    <div ref={containerRef} data-cy="candle-bar">
      <svg
        width={'100%'}
        style={{ overflow: 'visible' }}
        height={(height + shadowHeight) * 4}
      >
        <g transform="translate(5, 18)">
          {showHeader && (
            <text x={60} y={-5} textAnchor="middle">
              <tspan>
                {unit}
                {getFormattedNumber(current)}
              </tspan>
            </text>
          )}
          <rect
            x={0}
            y={0}
            width={'100%'}
            height={height}
            fill={'#EDECF6'}
            rx="1"
          />
          <rect
            x={open + padding}
            y={0}
            width={close - open - 2*padding}
            height={height}
            fill={candleColor}
            rx="1"
          />
          <line
            x1={currentValue + padding}
            y1={-shadowHeight}
            x2={currentValue + padding}
            y2={height + shadowHeight}
            stroke={isCurrentInRange ? '#465671' : 'rgb(255, 87, 51)'}
            strokeWidth={2}
          />
          {showOpenValue && (
            <text
              x={open + padding}
              y={(height + shadowHeight) * 2}
              textAnchor={calculatePosition(open) > 0.5 ? 'middle' : 'left'}
              style={{ fontSize: 13 }}
            >
              {typeof opening === 'number' && typeof closing === 'number' && (
                <tspan data-cy="opening" fill={candleColor}>
                  {unit !== '%' && unit}
                  {getFormattedNumber(opening)}
                  {unit == '%' ? unit : ''}
                </tspan>
              )}
            </text>
          )}
          {showCloseValue && (
            <text
              x={calculateClosingPosition()}
              y={(height + shadowHeight) * 2}
              textAnchor={calculatePosition(close) < 97 ? 'middle' : 'right'}
              style={{ fontSize: 13 }}
            >
              {typeof opening === 'number' && typeof closing === 'number' && (
                <tspan data-cy="closing" fill={candleColor}>
                  {unit !== '%' && unit}
                  {getFormattedNumber(closing)}
                  {unit == '%' ? unit : ''}
                </tspan>
              )}
            </text>
          )}
        </g>
      </svg>
    </div>
  );
};

const HorizontalCandleStick = props => {
  return (
    <ParentSize style={{ width: '100%' }}>
      {parent => <CandleStick barWidth={parent.width} {...props} />}
    </ParentSize>
  );
};

export default HorizontalCandleStick;
