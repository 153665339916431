import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useGetMedicationSpend } from '../../../queries/overview';
import SummaryCard from '../../shared/SummaryCard';

export default function MedicationSpend({ healthSystemId, hospitalId, type, fetching }) {
  const [frequency, setFrequency] = useState('Weekly');
  let medicationSpendData, medicationRange, medicationDescription;

  let { data, isFetching, isLoading, error } = useQuery(
    ['getMedicationSpendData', healthSystemId, hospitalId, frequency, type],
    () =>
      useGetMedicationSpend({
        healthSystemId,
        hospitalId,
        frequency
      }),
    {
      enabled: type
        ? !!healthSystemId && !!hospitalId && !!frequency
        : (!!healthSystemId || !!hospitalId) && !!frequency
    }
  );

  if (data) {
    medicationSpendData = data.medicationSpendDashTabMetric;
    medicationRange = frequency;
    const day =
      frequency === 'Monthly'
        ? 'Month'
        : frequency === 'Weekly'
          ? 'Week'
          : frequency === 'Yearly'
            ? 'Year'
            : 'Day';
    if (medicationSpendData?.percentageChange) {
      if (medicationSpendData?.percentageChange > 0) {
        medicationDescription = `${medicationSpendData?.percentageChange}% up from last ${day}`;
      } else {
        medicationDescription = `${medicationSpendData?.percentageChange}% down from last ${day}`;
      }
    }
  }

  const medicationSpend = {
    title: 'Medication Spend',
    date: '01-08-2021',
    info: 'Information about Medication spend',
    range: medicationRange,
    value: medicationSpendData,
    description: medicationDescription,
    icon: faDollarSign,
    iconColor: '#58d57e',
    healthSystemId
  };

  return (
    <SummaryCard
      data={medicationSpend}
      updateRange={setFrequency}
      dollarSign={true}
      range={true}
      fetching={isLoading || isFetching}
      error={error}
    />
  );
}
