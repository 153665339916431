import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getSavingRevenueOpportunity } from '../../../queries/overview';
import SummaryCard from '../../shared/SummaryCard';

export default function SavingOpportunity({
  healthSystemId,
  hospitalId,
  type
}) {
  const frequency = 'Daily';
  let savingRevenueOpportunityData,
    savingRevenueOpportunityDescription = null;

  // const [result] = getSavingRevenueOpportunity({
  //   healthSystemId,
  //   hospitalId,
  //   frequency
  // });
  // const { fetching, data, error } = result;

  const { data, isFetching, isLoading, error } = useQuery(
    [
      'getSavingRevenueOpportunity',
      healthSystemId,
      hospitalId,
      frequency,
      type
    ],
    () =>
      getSavingRevenueOpportunity({
        healthSystemId,
        hospitalId,
        frequency
      }),
    {
      enabled: type
        ? !!healthSystemId && !!hospitalId && !!frequency
        : (!!healthSystemId || !!hospitalId) && !!frequency
    }
  );

  if (data) {
    savingRevenueOpportunityData = data.savingRevenueOpportunityDashTabMetric;
    savingRevenueOpportunityDescription = `Over Last 6 Months`;
  }

  const savingOpportunity = {
    title: 'Saving & Revenue Opportunity',
    info: 'Information about Medication spend',
    range: null,
    value: savingRevenueOpportunityData,
    description: savingRevenueOpportunityDescription,
    icon: faDollarSign,
    iconColor: '#58d57e',
    healthSystemId,
    footer: [
      {
        description: 'Saving Potential',
        value: savingRevenueOpportunityData?.savingPotential,
        prefix: '$',
        color: '#58d57e'
      },
      {
        description: 'Recognized saving',
        value: savingRevenueOpportunityData?.realized,
        prefix: '$',
        color: '#58d57e'
      }
    ]
  };

  return (
    <SummaryCard
      data={savingOpportunity}
      subTitle={true}
      fetching={isLoading||isFetching}
      info={
        'Summary of opportunities associated with recommended medication strategy'
      }
    />
  );
}
