import { faDollarSign, faUsers } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getNewPatient } from '../../../queries/overview';
import SummaryCard from '../../shared/SummaryCard';

export default function NewAdmission({ healthSystemId, hospitalId, type }) {
  const [frequency, setFrequency] = useState('Weekly');

  let newPatientData,
    newPatientRange,
    newPatientDescription = null;

  const { data, isFetching, isLoading, error } = useQuery(
    ['getNewPatient', healthSystemId, hospitalId, frequency, type],
    () =>
      getNewPatient({
        healthSystemId,
        hospitalId,
        frequency
      }),
    {
      enabled: type
        ? !!healthSystemId && !!hospitalId && !!frequency
        : (!!healthSystemId || !!hospitalId) && !!frequency
    }
  );

  if (data) {
    newPatientData = data.newPatientDashTabMetric;
    newPatientRange = frequency;
  }

  const newPatient = {
    title: 'Discharged Patients',
    date: '01-08-2021',
    info: 'Information about Medication spend',
    range: newPatientRange,
    value: newPatientData,
    description: newPatientDescription,
    icon: faUsers,
    iconColor: '#58d57e',
    healthSystemId
  };

  return (
    <SummaryCard
      data={newPatient}
      updateRange={setFrequency}
      rangeParams={false}
      range={true}
      fetching={isLoading || isFetching}
    />
  );
}
