import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getPhysicianTrend } from '../../../queries/overview';
import SummaryCard from '../../shared/SummaryCard';

export default function PhysicianTrend({ healthSystemId, hospitalId, type }) {
  const frequency = 'Daily';
  let physicianTrendData,
    physicianTrendDescription = null;

  const { data, isFetching, isLoading, error } = useQuery(
    ['getPhysicianTrend', healthSystemId, hospitalId, frequency],
    () =>
      getPhysicianTrend({
        healthSystemId,
        hospitalId,
        frequency,
        type
      }),
    {
      enabled: type
        ? !!healthSystemId && !!hospitalId && !!frequency
        : (!!healthSystemId || !!hospitalId) && !!frequency
    }
  );

  if (data) {
    physicianTrendData = data.physicianTrendDashTabMetric;
    physicianTrendDescription = `Physicians with high score`;
  }

  const physicianTrend = {
    title: 'Physician Trend',
    info: 'Information about Medication spend',
    value: physicianTrendData,
    range: null,
    description: physicianTrendDescription,
    icon: faDollarSign,
    iconColor: '#58d57e',
    healthSystemId,
    footer: [
      {
        description: 'Cases closed, last 12 months',
        value: `${physicianTrendData?.closedCount}`,
        color: '#465671'
      }
    ]
  };

  return (
    <SummaryCard
      fetching={isLoading||isFetching}
      data={physicianTrend}
      subTitle={true}
      info="Summary of opportunities associated with physician prescribing behavior"
    />
  );
}
