import {
  faDollarSign,
  faPerson,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getMedicationSpendPerPatient } from '../../../queries/overview';
import SummaryCard from '../../shared/SummaryCard';

export default function MedicationSpendPerPatient({
  healthSystemId,
  hospitalId,
  type
}) {
  const [frequency, setFrequency] = useState('Weekly');

  let medicationSpendPerPatientData,
    medicationSpendPerPatientRange,
    medicationSpendPerPatientDescription = null;

  // const getMedicationSpendPerPatientData=(query)=>{

  const { data, isFetching, isLoading, error } = useQuery(
    [
      'getMedicationSpendPerPatient',
      healthSystemId,
      hospitalId,
      frequency,
      type
    ],
    () =>
      getMedicationSpendPerPatient({
        healthSystemId,
        hospitalId,
        frequency
      }),
    {
      enabled: type
        ? !!healthSystemId && !!hospitalId && !!frequency
        : (!!healthSystemId || !!hospitalId) && !!frequency
    }
  );

  if (data) {
    medicationSpendPerPatientData = data.medicationSpendPerPatientDashTabMetric;
    medicationSpendPerPatientRange = frequency;
    if (medicationSpendPerPatientData?.percentageChange) {
      if (medicationSpendPerPatientData?.percentageChange > 0) {
        medicationSpendPerPatientDescription = `${medicationSpendPerPatientData?.percentageChange}% up from last Day`;
      } else {
        medicationSpendPerPatientDescription = `${medicationSpendPerPatientData?.percentageChange}% down from last Day`;
      }
    }
  }
  // }

  // getMedicationSpendPerPatientData({frequency: 'Daily'});

  const medicationSpendPerPatient = {
    title: 'Medication Spend Per Patient',
    date: '01-08-2021',
    info: 'Information about Medication spend',
    range: medicationSpendPerPatientRange,
    value: medicationSpendPerPatientData,
    description: medicationSpendPerPatientDescription,
    icon: faUser,
    iconColor: '#58d57e',
    healthSystemId
    // fetchData: getMedicationSpendPerPatientData,
  };

  return (
    <SummaryCard
      dollarSign={true}
      wavyLine={true}
      data={medicationSpendPerPatient}
      fetching={isLoading||isFetching}
      updateRange={setFrequency}
      range={true}
    />
  );
}
